import useGraphql from "@/composables/useGraphql";
import { defineStore, acceptHMRUpdate } from "pinia";
import * as cardQueries from "@/graphql/queries/customers/cards";
import {
  CustomersCardType,
  CustomersPayjpCardType,
  CustomersGmoPgCardType,
  CustomerPaygentCardType,
} from "@/types/customers/CustomersCardType";
import { CommonType } from "@/types/customers/CommonType";

interface State {
  payjpCards: CustomersPayjpCardType[];
  gmoPgCards: CustomersGmoPgCardType[];
  softbankCards: CustomersCardType[];
  paygentCards: CustomerPaygentCardType[];
}

export const useCardsStore = defineStore({
  id: "cards",

  state: (): State => ({
    payjpCards: [],
    gmoPgCards: [],
    softbankCards: [],
    paygentCards: [],
  }),

  actions: {
    fetchPayjpCards() {
      useGraphql<CustomersPayjpCardType[]>(cardQueries.payjpCards).then(
        (data) => (this.payjpCards = data),
      );
    },

    async fetchSoftbankCards() {
      const data = (await useGraphql<CustomersCardType[]>(cardQueries.softbankCards)) as any;
      if (data.brand) {
        this.softbankCards = [data];
      }
    },

    deleteSoftbankCards() {
      useGraphql<CommonType>(cardQueries.deleteSoftbankCards, {}, { toasted: true }).then(
        this.fetchSoftbankCards,
      );
    },

    modifySoftbankCards(cardToken: string, cardTokenKey: string) {
      useGraphql(
        cardQueries.modifySoftbankCards,
        { cardToken, cardTokenKey },
        { toasted: true },
      ).then(this.fetchSoftbankCards);
    },

    payjpCardDelete(id: string) {
      useGraphql<CommonType>(
        cardQueries.customersPayjpCardDestroy,
        {
          id: id,
        },
        { toasted: true },
      ).then(() => {
        useCardsStore().fetchPayjpCards();
      });
    },

    createPayjpCard(cardToken: string) {
      useGraphql(cardQueries.createPayjpCard, { cardToken }, { toasted: true }).then(
        this.fetchPayjpCards,
      );
    },

    fetchGmoPgCards() {
      useGraphql<CustomersGmoPgCardType[]>(cardQueries.gmoPgCards).then(
        (data) => (this.gmoPgCards = data),
      );
    },

    createGmoPgCards(cardToken: string) {
      useGraphql<CommonType>(
        cardQueries.customersGmoPgCardCreate,
        { cardToken },
        { toasted: true },
      ).then(this.fetchGmoPgCards);
    },

    deleteGmoPgCards(cardSeq: string) {
      useGraphql<CommonType>(
        cardQueries.customersGmoPgCardDelete,
        { cardSeq },
        { toasted: true },
      ).then(this.fetchGmoPgCards);
    },

    fetchPaygentCards() {
      useGraphql<CustomersPaygentCardType[]>(cardQueries.paygentCards).then(
        (data) => (this.paygentCards = data),
      );
    },

    createPaygentCards(cardToken: string) {
      useGraphql<CommonType>(
        cardQueries.customersPaygentCardCreate,
        { cardToken },
        { toasted: true },
      ).then(this.fetchPaygentCards);
    },

    deletePaygentCards(id: string) {
      useGraphql<CommonType>(
        cardQueries.customersPaygentCardDelete,
        { id },
        { toasted: true },
      ).then(this.fetchPaygentCards);
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCardsStore, import.meta.hot));
}
