const CARD_REQUIRED_PAYMENT_METHOD_CODES = [
  "payjp",
  "gmo_creditcard_one_time",
  "sbps_creditcard_one_time",
  "paygent_creditcard_one_time",
];

const SOFT_BANK_PAYMENT_METHOD_CODE = "sbps_creditcard_one_time";
const PAYGENT_PAYMENT_METHOD_CODE = "paygent_creditcard_one_time";
const AVAILABLE_IMAGE_CARD_BRAND = ["americanexpress","applepay","dinersclub","discover","jcb","mastercard","visa"]
export { CARD_REQUIRED_PAYMENT_METHOD_CODES, SOFT_BANK_PAYMENT_METHOD_CODE, AVAILABLE_IMAGE_CARD_BRAND };
