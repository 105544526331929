export const selectOptions = `
  query ($keys: [String!]!) {
    selectOptions(keys: $keys) {
      sexes {
        text
        value
      }
      prefectures {
        text
        value
      }
      dayOfWeekJpOptions {
        text
        value
      }
      subsOrderReasonInMyPage {
        text
        value
      }
      contactTypeOptions {
        text
        value
      }
    }
  }
`;

export const fetchShippingCarrierOptions = `
query ($input: OrderFetchShippingCarrierInput!) {
  ordersFetchShippingCarrierOptions(input: $input) {
    id
    name
    default
  }
}
`;

export const fetchShippingCarrierDefault = `
  query {
    ordersFetchShippingCarrierDefault{
        id
        name
    }
  }
`;

export const fetchShippingCarrierPaymentMethod = `
query ($paymentMethodCode: String!) {
    ordersFetchShippingCarrierPaymentMethod(paymentMethodCode: $paymentMethodCode) {
        id
        name
    }
  }
`;
