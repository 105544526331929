<template>
  <div
    class="form-validator"
    :class="{ 'hide-errors': !showErrors, validate: props.name || props.clientName }"
    @click="showErrors = false"
    @focusout="validate"
    href=""
  >
    <div
      class="form-group"
      :class="{
        'has-danger': errors.length || serverErrors.length,
        float,
        'hide-float': !showFloat,
        row,
      }"
    >
      <label v-if="label" class="form-label" :class="{ 'col-lg-4 py-4 mb-0': row, required }">
        {{ label }}
      </label>
      <div :class="{ 'col-lg-8': row }" class="align-self-center position-relative">
        <slot></slot>
        <div v-if="serverErrors && serverErrors.length" class="invalid-feedback d-block">
          <div v-for="(error, index) in serverErrors" :key="index">
            {{ error }}
          </div>
        </div>
        <div
          v-if="errors && errors.length"
          class="invalid-feedback d-block"
          @click="showFloat = false"
        >
          <div v-for="(error, index) in errors" :key="index">
            {{ error }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch, onBeforeUnmount } from "vue";
import { useField, useIsFieldValid } from "vee-validate";
import { useGlobalStore } from "@/stores/global";
import { useValidationStore } from "@/stores/form/validation";

interface Props {
  label?: string;
  name?: string;
  clientName?: string;
  required?: boolean;
  float?: boolean;
  row?: boolean;
  disabled?: boolean;
}

const props = defineProps<Props>();

const globalStore = useGlobalStore();
const validationStore = useValidationStore();

const serverErrors = computed(() => {
  if (!props.name) return [];
  return globalStore.getErrors(props.name);
});

const { value, errors, validate: validateField } = useField(props.clientName || props.name || "");

const showFloat = ref(props.float);
const showErrors = ref(true);
const submitAttempts = computed(() => validationStore.submitAttempts);
const validate = () => {
  showFloat.value = true;
  showErrors.value = true;
  validateField();
};

watch(submitAttempts, () => {
  showErrors.value = true;
  showFloat.value = true;
});

watch(serverErrors, () => (showFloat.value = true));

watch(value, () => {
  if (!value.value) showErrors.value = true;
  globalStore.validationErrors = {};
});

onMounted(() => {
  if (!submitAttempts.value && !globalStore.keepError) showErrors.value = false;
  if (props.clientName) {
    validationStore.fieldsValidStatus[props.clientName] =
      useIsFieldValid(props.clientName || props.name || "") && value;

    validationStore.fieldsRequiredStatus[props.clientName] = true;
  }
});

onBeforeUnmount(async () => {
  if (!props.clientName) return;

  delete validationStore.fieldsValidStatus[props.clientName];
  delete validationStore.fieldsRequiredStatus[props.clientName];
});
</script>

<style lang="scss">
.form-validator.form-validator.validate {
  .form-group {
    &.has-danger {
      .form-control,
      .form-select {
        border-color: #f46a6a;
      }

      &.float {
        position: relative;

        .invalid-feedback {
          position: absolute;
          bottom: calc(100% + 10px);
          width: 300px;
          border-radius: 6px;
          background: #f2850c;
          opacity: 0.95;
          padding: 8px;
          border: 2px solid #ddd;
          box-shadow: 0 0 6px #000;
          color: white;
          cursor: pointer;
          z-index: 1;

          div::before {
            content: "* ";
          }

          &:after {
            content: "";
            position: absolute;
            border: 1px solid rgba(51, 51, 51, 0.19);
            border-color: #f2850c transparent;
            border-width: 10px 10px 0 10px;
            bottom: -10px;
            opacity: 0.95;
            left: 20px;
          }

          &:before {
            content: "";
            position: absolute;
            border: 1px solid #333;
            border-color: #ddd transparent;
            border-width: 9px 10px 0 10px;
            bottom: -10px;
            opacity: 0.95;
            left: 20px;
          }
        }

        &.hide-float {
          .invalid-feedback {
            display: none !important;
          }
        }
      }
    }

    .form-label.required::after {
      content: "*";
      color: #f46a6a;
      margin-left: 5px;
    }
  }
}

.form-validator.validate.hide-errors {
  .invalid-feedback {
    display: none !important;
  }
  .form-control,
  .form-select {
    border-color: #e4e6ef !important;
  }
}
</style>
