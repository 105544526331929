<template>
  <div>
    <form @submit.prevent="submitForm">
      <div v-if="type == 'cart'" class="py-3">下記項目にご入力の上、先にログインしてください。</div>
      <b-card class="mb-3">
        <template #header>ログイン</template>

        <div class="d-grid gap-3">
          <FormValidator label="メールアドレス" name="email" required float>
            <b-input
              v-model="email"
              placeholder="例）○○○@example.com"
              type="email"
              name="login_mail"
              id="login_mail"
            ></b-input>
          </FormValidator>

          <FormValidator label="パスワード" name="password" required float>
            <b-input
              v-model="password"
              placeholder="半角英数字8文字以上（大文字小文字をそれぞれ含む）"
              type="password"
              name="login_password"
              id="login_password"
            ></b-input>
          </FormValidator>

          <div class="text-center mt-3">
            <a href="/shop/customer/password/new">パスワードを忘れた方はこちら</a>
          </div>
          <div class="text-center">
            <a href="/shop/customers/sign_up">まだ会員登録されていないお客様</a>
          </div>
        </div>
      </b-card>
      <div class="text-center mb-5">
        <b-button size="lg" variant="success" type="submit" name="login_btn" id="login_btn">
          ログイン
        </b-button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { useField, useForm } from "vee-validate";
import { useAuthStore } from "@/stores/auth";
import { SharedSignInType } from "@/types/SharedSignInType";
import * as yup from "yup";
import * as authQueries from "@/graphql/queries/customers/auth";
import useGraphql from "@/composables/useGraphql";
import { useValidationStore } from "@/stores/form/validation";
import { createToaster } from "@meforma/vue-toaster";

interface Props {
  willLogin?: boolean;
  type: string;
}

const props = defineProps<Props>();

defineEmits(["update:willLogin"]);

const authStore = useAuthStore();
const validationStore = useValidationStore();
const toast = createToaster({ position: "top-right" });

const {
  handleSubmit,
  validate,
  values: formValues,
} = useForm({
  validationSchema: yup.object({
    email: yup.string().required().email(),
    password: yup.string().required().min(8),
    rememberMe: yup.boolean(),
  }),
  initialValues: {
    email: "",
    password: "",
    rememberMe: false,
  },
});

const submitForm = async () => {
  validationStore.submitAttempts++;

  const result = await validate();

  if (!result.valid) return;

  useGraphql<SharedSignInType>(authQueries.signIn, formValues, { toasted: true }).then(
    (response) => {
      authStore.setTokenAndCookie(response.token);

      if (props.type == "common") window.location.href = "/shop/customer";
      else window.location.reload();
    },
  );
};

const { value: email } = useField("email");
const { value: password } = useField("password");

if (localStorage.getItem("toast")) {
  {
    toast.success("パスワードの再設定について数分以内にメールでご連絡いたします。");
    localStorage.removeItem("toast");
  }
}
</script>
