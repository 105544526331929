import { CreditCardType } from "@/types/creditCard";
import { ref } from "vue";
import { Multipayment } from "@/types/modules/gmoPg";
import tokenGmoPgError from "@/helpers/gmo_pg_errors";

const gmo = ref<Multipayment | null>(null);

const linkCreditCard = (creditCard: CreditCardType, gmoShopId: string, toast: any, onSuccess) => {
  gmo.value = window.Multipayment;

  const expiry =
    new Date().getFullYear().toString().substr(0, 2) +
    creditCard.cardExpiry.split("/")[1] +
    creditCard.cardExpiry.split("/")[0];

  const card = {
    holdername: creditCard.cardName,
    cardno: creditCard.cardNumber.replace(/\s/g, ""),
    expire: expiry,
    securitycode: creditCard.cardCvc,
  };

  gmo.value.init(gmoShopId);

  gmo.value.getToken(card, function (response: any) {
    if (response.resultCode != "000") {
      const errors = tokenGmoPgError as any;
      toast.error(errors[response.resultCode]);
    } else onSuccess(response.tokenObject.token);
  });
};

export { linkCreditCard };
