<template>
  <div>
    <div class="d-grid gap-5">
      <FormValidator label="お名前" required>
        <b-row gutter-y="3">
          <b-col md="6">
            <FormValidator
              float
              :name="`${prefixNameValidatorMapper}name01`"
              :clientName="`${prefixMapper}name01`"
            >
              <b-form-input
                v-model="props.addressData.name01"
                placeholder="例）山田"
                @input="handleInputKana($event, 'name01')"
                :name="isShipping ? 'shipping_name_family' : 'order_name_family'"
                :id="isShipping ? 'shipping_name_family' : 'order_name_family'"
              />
            </FormValidator>
          </b-col>

          <b-col md="6">
            <FormValidator
              float
              :name="`${prefixNameValidatorMapper}name02`"
              :clientName="`${prefixMapper}name02`"
            >
              <b-form-input
                v-model="props.addressData.name02"
                placeholder="例）花子"
                @input="handleInputKana($event, 'name02')"
                :name="isShipping ? 'shipping_name_first' : 'order_name_first'"
                :id="isShipping ? 'shipping_name_first' : 'order_name_first'"
              />
            </FormValidator>
          </b-col>
        </b-row>
      </FormValidator>

      <FormValidator label="フリガナ" required>
        <b-row gutter-y="3">
          <b-col md="6">
            <FormValidator
              float
              :name="`${prefixNameValidatorMapper}kana01`"
              :clientName="`${prefixMapper}kana01`"
            >
              <b-form-input
                v-model="props.addressData.kana01"
                placeholder="例）ヤマダ"
                :name="isShipping ? 'shipping_kana_family' : 'order_kana_family'"
                :id="isShipping ? 'shipping_kana_family' : 'order_kana_family'"
              />
            </FormValidator>
          </b-col>

          <b-col md="6">
            <FormValidator
              float
              :name="`${prefixNameValidatorMapper}kana02`"
              :clientName="`${prefixMapper}kana02`"
            >
              <b-form-input
                v-model="props.addressData.kana02"
                placeholder="例）ハナコ"
                :name="isShipping ? 'shipping_kana_first' : 'order_kana_first'"
                :id="isShipping ? 'shipping_kana_first' : 'order_kana_first'"
              />
            </FormValidator>
          </b-col>
        </b-row>
      </FormValidator>

      <template v-if="!hideAddressSection">
        <FormValidator label="郵便番号" required>
          <div class="d-flex align-items-start">
            <div class="form-group">
              <span class="form-control border-0">〒</span>
            </div>
            <FormValidator
              float
              :name="`${prefixNameValidatorMapper}zip01`"
              :clientName="`${prefixMapper}zip01`"
            >
              <b-form-input
                v-model="props.addressData.zip01"
                @input="updateAddressByZipCode"
                placeholder="例）150"
                maxlength="3"
                :name="isShipping ? 'shipping_address_postcode_0' : 'order_address_postcode_0'"
                :id="isShipping ? 'shipping_address_postcode_0' : 'order_address_postcode_0'"
              />
            </FormValidator>
            <div class="form-group">
              <span class="form-control border-0">ー</span>
            </div>
            <FormValidator
              float
              :name="`${prefixNameValidatorMapper}zip02`"
              :clientName="`${prefixMapper}zip02`"
            >
              <b-form-input
                v-model="props.addressData.zip02"
                @input="updateAddressByZipCode"
                placeholder="例）0031"
                maxlength="4"
                :name="isShipping ? 'shipping_address_postcode_1' : 'order_address_postcode_1'"
                :id="isShipping ? 'shipping_address_postcode_1' : 'order_address_postcode_1'"
              />
            </FormValidator>
          </div>
        </FormValidator>

        <FormValidator
          label="都道府県"
          float
          :name="`${prefixNameValidatorMapper}prefecture_id`"
          :clientName="`${prefixMapper}prefectureId`"
          required
        >
          <b-form-select
            v-model="props.addressData.prefectureId"
            :options="prefectureOptions"
            :name="
              isShipping ? 'shipping_address_prefecture_name' : 'order_address_prefecture_name'
            "
            :id="isShipping ? 'shipping_address_prefecture_name' : 'order_address_prefecture_name'"
          >
            <template #first>
              <option :value="''">選択してください</option>
            </template>
          </b-form-select>
        </FormValidator>

        <FormValidator
          label="住所1（市郡区／町・村）"
          float
          :name="`${prefixNameValidatorMapper}addr01`"
          :clientName="`${prefixMapper}addr01`"
          required
        >
          <b-form-input
            v-model="props.addressData.addr01"
            placeholder="例）○○市△△区□町"
            :name="isShipping ? 'shipping_address_0' : 'order_address_0'"
            :id="isShipping ? 'shipping_address_0' : 'order_address_0'"
          />
        </FormValidator>

        <FormValidator
          label="住所2（丁目・番地・マンション名・号室）"
          float
          :name="`${prefixNameValidatorMapper}addr02`"
          :clientName="`${prefixMapper}addr02`"
          required
        >
          <b-form-input
            v-model="props.addressData.addr02"
            placeholder="例）△△ 1-4 ○○マンション101号"
            :name="isShipping ? 'shipping_address_1' : 'order_address_1'"
            :id="isShipping ? 'shipping_address_1' : 'order_address_1'"
          />
        </FormValidator>
      </template>

      <FormValidator label="電話番号" required>
        <div class="d-flex align-items-start gap-2">
          <FormValidator
            float
            :name="`${prefixNameValidatorMapper}tel01`"
            :clientName="`${prefixMapper}tel01`"
          >
            <b-form-input
              v-model="props.addressData.tel01"
              placeholder="例）000"
              maxlength="5"
              :name="isShipping ? 'shipping_tel_0' : 'order_tel_0'"
              :id="isShipping ? 'shipping_tel_0' : 'order_tel_0'"
            />
          </FormValidator>
          <FormValidator
            float
            :name="`${prefixNameValidatorMapper}tel02`"
            :clientName="`${prefixMapper}tel02`"
          >
            <b-form-input
              v-model="props.addressData.tel02"
              placeholder="例）0000"
              maxlength="4"
              :name="isShipping ? 'shipping_tel_1' : 'order_tel_1'"
              :id="isShipping ? 'shipping_tel_1' : 'order_tel_1'"
            />
          </FormValidator>
          <FormValidator
            float
            :name="`${prefixNameValidatorMapper}tel03`"
            :clientName="`${prefixMapper}tel03`"
          >
            <b-form-input
              v-model="props.addressData.tel03"
              placeholder="例）0000"
              maxlength="4"
              :name="isShipping ? 'shipping_tel_2' : 'order_tel_2'"
              :id="isShipping ? 'shipping_tel_2' : 'order_tel_2'"
            />
          </FormValidator>
        </div>
      </FormValidator>
    </div>
  </div>
</template>
<script setup lang="ts">
import { findByName } from "jp-prefectures";
import { computed, onMounted, ref } from "vue";
import { toKatakana, isKana } from "wanakana";
import { AddressInterface } from "@/types/shared/AddressInterface";
import { SharedGeneralsSelectOptionsType } from "@/types/shared/generals/SharedGeneralsSelectOptionsType";

import postalCode from "jp-postalcode-lookup";
import useGraphql from "@/composables/useGraphql";
import * as commonQueries from "@/graphql/queries/common";

interface PostalcodeResult {
  prefectureId: string;
  prefecture: string;
  prefectureKana: string;
  cityId: string;
  city: string;
  cityKana: string;
  areaId: string;
  area: string;
  areaKana: string;
  street: string;
  address: string;
}

interface Props {
  prefix: string;
  prefixNameValidator: string;
  addressData: AddressInterface;
  hideAddressSection?: boolean;
  isShipping?: boolean;
}

const props = defineProps<Props>();
const zipcode = computed(
  () => (props.addressData.zip01 as string) + (props.addressData.zip02 as string),
);

const prefectureOptions = ref([]) as any;

const prefixMapper = computed(() => (props.prefix ? `${props.prefix}.` : ""));
const prefixNameValidatorMapper = computed(() =>
  props.prefixNameValidator ? `${props.prefixNameValidator}.` : "",
);

const fetchSelectOptions = () => {
  useGraphql<SharedGeneralsSelectOptionsType>(commonQueries.selectOptions, {
    keys: ["prefectures"],
  }).then((data) => {
    prefectureOptions.value = data.prefectures;
  });
};

onMounted(fetchSelectOptions);

function handleInputKana(event: any, field: string) {
  if (!isKana(event)) return;

  const kanaField = toKatakana(event);

  if (field === "name01") props.addressData.kana01 = kanaField;
  else props.addressData.kana02 = kanaField;
}

function updateAddressByZipCode() {
  if (zipcode.value.length !== 7) return;

  postalCode.get(zipcode.value, (results: PostalcodeResult) => {
    if (results) {
      props.addressData.prefectureId = findByName(results.prefecture)?.code || 0;
      props.addressData.addr01 = results.city + results.area + results.street;
    }
  });
}
</script>

<style lang="scss" scoped>
:deep(.input-tel) {
  .form-group {
    max-width: 33%;
  }

  .invalid-feedback > div {
    width: 400px;
    white-space: pre-line;
    @media (max-width: 768px) {
      width: 220px;
    }
  }
}
</style>
