import { defineStore, acceptHMRUpdate } from "pinia";
import { filter } from "lodash"

interface State {
  fieldsRequiredStatus: any;
  fieldsValidStatus: any;
  submitAttempts:number; // this is capturing the event of submit button being click
  setErrors: any
}

export const useValidationStore = defineStore({
  id: "validation",

  state: (): State => ({
    fieldsRequiredStatus: {},
    fieldsValidStatus: {},
    submitAttempts:0,
    setErrors: null
  }),

  getters: {
    numberOfRequiredFields() {
      return Object.keys(this.fieldsRequiredStatus).length
    },
    numberOfRemainFields() {
      const validFields = filter(this.fieldsValidStatus, (value: boolean) => value)
      const numberOfValidFields = Object.keys(validFields).length
      return this.numberOfRequiredFields - numberOfValidFields
    },
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useValidationStore, import.meta.hot));
}
