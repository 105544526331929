export const url = `
  query ($baseUrl: String!, $userAgent: String!) {
    url(baseUrl: $baseUrl, userAgent: $userAgent) {
      baseUrl
      products {
        id
        variantMasterId
        name
        masterSalesPrice
        purchaseLimit
        purchaseMax
        purchaseMin
        isRecurring
        paymentSchedule
        scheduledToBeDeliveredEveryXMonth
        scheduledToBeDeliveredOnXthDay
        scheduledToBeDeliveredEveryXDay
        scheduledToBeDeliveredEveryXDayOfWeek
        scheduledToBeDeliveredOnXthDayOfWeek
        variantCombinations
        variants {
          id
          minQuantity
          maxQuantity
          isMaster
        }
        recurringOptions {
          dateOptions {
              text
              value
          }
          termOptions {
              text
              value
          }
        }
        upsellProduct {
          id
          variantMasterId
          name
          masterSalesPrice
          purchaseLimit
          purchaseMax
          purchaseMin
          isRecurring
          paymentSchedule
          scheduledToBeDeliveredEveryXMonth
          scheduledToBeDeliveredOnXthDay
          scheduledToBeDeliveredEveryXDay
          scheduledToBeDeliveredEveryXDayOfWeek
          scheduledToBeDeliveredOnXthDayOfWeek
          variantCombinations
          variants {
            id
            minQuantity
            maxQuantity
            isMaster
          }
          recurringOptions {
            dateOptions {
              text
              value
            }
            termOptions {
              text
              value
            }
          }
        }
      }
      recurringOptions {
        paymentScheduleOptions {
          text
          value
        }
        dateOptions {
          text
          value
        }
        termOptions {
          text
          value
        }
      }
      availablePaymentMethodOptions {
        text
        value
      }
      availablePaymentMethods {
        code
        contentDevice
      }
      upsell
      buyMultipleVariant
      deliveryCompanyDisplay
      confirmEmailDisplay
      passwordDisplay
      confirmPasswordDisplay
      normalProductAllowNilInDeliveryDate
      normalProductAllowScheduleDeliveryDate
      normalProductAllowScheduleDeliveryTime
      normalProductShowScheduledDeliveryDates
      subsProductAllowScheduleDeliveryDate
      subsProductAllowScheduleDeliveryTime
      subsProductShowScheduledDeliveryDates
      subsProductAllowNilInDeliveryDate
      subsProductAllowScheduleDeliveryCycleByDate
      subsProductAllowScheduleDeliveryCycleByDayOfWeek
      subsProductAllowScheduleDeliveryCycleByTerm
      productQuantityDisplay
      generalVariantDisplay
      ownVariantDisplay
      caution02Display
      cautionDisplay
      productsUrls {
        id
        productId
        minQuantity
        maxQuantity
        default
        product {
          id
          isRecurring
          position
          variants {
            id
            minQuantity
            maxQuantity
            isMaster
          }
        }
      }
    }
  }
`;

export const deliveryTimes = `
query ($shippingCarrierId: ID!) {
  ordersShippingCarrierDeliveryTimesFetchList(shippingCarrierId: $shippingCarrierId) {
      text
      value
  }
}
`;

export const orderSetting = `
query ($prefectureId: ID){
  orderSettings(prefectureId: $prefectureId) {
      deliveryDateAtOptions {
        text
        value
      }
  }
}
`;

export const submitCart = `
  mutation ($input: PublicsOrderInput!, $userAgent: String) {
    ordersCreate(input: $input, userAgent: $userAgent) {
      orderNumber
      message
      token
      cvUpsellAvailable
      cvXsellAvailable
    }
  }
`;

export const validateCart = `
  query ($input: PublicsOrderInput!) {
    ordersValidate(input: $input) {
      total
      subtotal
      tax
      delivFee
      charge
      variants {
        id
        name
        salesPrice
        quantity
        total
        product {
          sanitizeCaution02
          sanitizeCaution
        }
      }
      shippingCarrier {
        name
      }
    }
  }
`;

export const customer = `
  {
    customersSelf {
      email
      birthday
      sexId
      billingAddress {
        name01
        name02
        kana01
        kana02
        addr01
        addr02
        prefectureId
        tel01
        tel02
        tel03
        zip01
        zip02
      }
      shippingAddresses {
        id
        name01
        name02
        kana01
        kana02
        tel01
        tel02
        tel03
        fullAddress
        prefectureId
      }
    }
  }
`;

export const customersPayjpCards = `
  query {
    customersPayjpCards {
      id
      brand
      expMonth
      expYear
      last4
    }
  }
`;

export const customersSoftbankCards = `
query {
  customersSoftbankCards {
      brand
      expMonth
      expYear
      last4
  }
}
`;

export const customersGmoPgCards = `
query {
  customersGmoPgCards {
      cardSeq
      expMonth
      expYear
      brand
      last4
  }
}
`;

export const customersPaygentCards = `
query {
  customersPaygentCards {
      id
      expMonth
      expYear
      brand
      last4
  }
}
`;

export const ordersOfferFetch = `
  query ($orderNumber: String!) {
    ordersOfferFetch(orderNumber: $orderNumber) {
      buyMultipleVariant
      generalVariantDisplay
      ownVariantDisplay
      timeout
      paymentScheduleOptions {
        text
        value
      }
      cvUpsellProducts {
        id
        name
        variantMasterId
        masterSalesPrice
        recurringPrice
        purchaseLimit
        purchaseMax
        purchaseMin
        variantCombinations
        variants {
          id
          minQuantity
          maxQuantity
          isMaster
          salesPrice
          variantName {
            downLine
          }
        }
        paymentSchedule
        scheduledToBeDeliveredEveryXMonth
        scheduledToBeDeliveredOnXthDay
        scheduledToBeDeliveredEveryXDay
        scheduledToBeDeliveredEveryXDayOfWeek
        scheduledToBeDeliveredOnXthDayOfWeek
        isRecurring
        recurringOptions {
          dateOptions {
            text
            value
          }
          termOptions {
            text
            value
          }
        }
      }
      cvXsellProducts {
        id
        name
        variantMasterId
        masterSalesPrice
        recurringPrice
        purchaseLimit
        purchaseMax
        purchaseMin
        variantCombinations
        variants {
          id
          minQuantity
          maxQuantity
          isMaster
          salesPrice
          variantName {
            downLine
          }
        }
        paymentSchedule
        scheduledToBeDeliveredEveryXMonth
        scheduledToBeDeliveredOnXthDay
        scheduledToBeDeliveredEveryXDay
        scheduledToBeDeliveredEveryXDayOfWeek
        scheduledToBeDeliveredOnXthDayOfWeek
        isRecurring
        recurringOptions {
          dateOptions {
            text
            value
          }
          termOptions {
            text
            value
          }
        }
      }
    }
  }
`;

export const ordersCvUpsellModificationValidate = `
  query ($input: PublicsCvUpsellOrderModificationInput!) {
    ordersCvUpsellModificationValidate(input: $input) {
      total
      subtotal
      tax
      delivFee
      charge
      variants {
        id
        name
        salesPrice
        quantity
        total
        product {
          sanitizeCaution02
          sanitizeCaution
        }
      }
      timeout
      shippingCarrier {
        name
      }
    }
  }
`;

export const ordersCvXsellModificationValidate = `
  query ($input: PublicsCvXsellOrderModificationInput!) {
    ordersCvXsellModificationValidate(input: $input) {
      total
      subtotal
      tax
      delivFee
      charge
      variants {
        id
        name
        salesPrice
        quantity
        total
        product {
          sanitizeCaution02
          sanitizeCaution
        }
      }
      timeout
      shippingCarrier {
        name
      }
    }
  }
`;

export const ordersCvUpsellModify = `
  mutation ($input: PublicsCvUpsellOrderModificationInput!) {
    ordersCvUpsellModify(input: $input) {
      orderNumber
      message
      token
    }
  }
`;

export const ordersCvXsellModify = `
  mutation ($input: PublicsCvXsellOrderModificationInput!) {
    ordersCvXsellModify(input: $input) {
      orderNumber
      message
      token
    }
  }
`;

export const publicsAdvertisementsCreatePvAdvertisement = `
  mutation ($input: PublicsAdvertisementInput!) {
    publicsAdvertisementsCreatePvAdvertisement(input: $input) {
      success
    }
  }
`;

export const calculateSubsOrderEstimate = `
  query ($input: PublicsCartSubsOrderEstimateInput!) {
    calculateSubsOrderEstimate(input: $input) {
      estimatedTime
    }
  }
`;

export const customersAccessHistoriesCreate = `
  mutation ($baseUrl: String) {
    customersAccessHistoriesCreate(baseUrl: $baseUrl) {
      success
    }
  }
`;
