import tokenSbpsError from "@/helpers/sbps_errors";
import { CreditCardType } from "@/types/creditCard";

const linkCreditCard = (
  creditCard: CreditCardType,
  settings: any,
  toast: any,
  onSuccess
) => {
  const expiry =
    new Date().getFullYear().toString().substr(0, 2) +
    creditCard.cardExpiry.split("/")[1] +
    creditCard.cardExpiry.split("/")[0];

  const params = {
    merchantId: settings.merchantId,
    serviceId: settings.serviceId,
    ccNumber: creditCard.cardNumber.replace(/\s/g, ""),
    ccExpiration: expiry,
    securityCode: creditCard.cardCvc,
  };

  window.com_sbps_system.generateToken(params, function (response: any) {
    if (response.result == "OK") {
      const cardToken = response.tokenResponse.token;
      const cardTokenKey = response.tokenResponse.tokenKey;
      onSuccess(cardToken, cardTokenKey);
    } else if (response.errorCode) {
      const error = tokenSbpsError(response.errorCode);
      toast.error(error);
    } else {
      toast.error("トークン取得に失敗しました。");
    }
  });
};

export { linkCreditCard };
