import { Element } from "@/types/modules/Payjp";

export interface CardType {
  cardNumber: Element | null;
  cardExpiry: Element | null;
  cardCvc: Element | null;
  cardName: string;
}

export interface CreaditCardType {
  cardNumber: string;
  cardExpiry: string;
  cardCvc: string;
  cardName: string;
}

export interface GmoCardType {
  cardNumber: string;
  cardExpiry: string;
  cardCvc: string;
  cardName: string;
}

export interface PayjpCreditCardType {
  cardNumber: Element | null;
  cardExpiry: Element | null;
  cardCvc: Element | null;
  cardName: string;
}

export class CreditCardInput implements CardType {
  public cardNumber = "";
  public cardExpiry = "";
  public cardCvc = "";
  public cardName = "";
}
export class PayjpCreditCardInput implements PayjpCreditCardType {
  public cardNumber = null;
  public cardExpiry = null;
  public cardCvc = null;
  public cardName = "";
}
