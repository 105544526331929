const sbpsError = {
  digit_1st_2nd: {
    "03": "必須エラー",
    "04": "属性エラー",
    "05": "桁数エラー",
    "07": "定義値エラー",
    99: "システムエラー",
  } as { [key: string]: string },

  digit_3rd_5th: {
    "001": "マーチャント ID",
    "002": "サービス ID",
    "003": "クレジットカード番号",
    "004": "クレジットカード有効期限",
    "005": "セキュリティコード",
    999: "(該当項目無し)",
  } as { [key: string]: string },
};

export default function tokenSbpsError(errorCode: String) {
  const typeError = errorCode.substring(0, 2);
  const keyError = errorCode.substring(2);

  return `${sbpsError.digit_1st_2nd[typeError]} ${sbpsError.digit_3rd_5th[keyError]}`;
}
