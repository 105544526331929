export const payjpCards = `
  query {
    customersPayjpCards {
      id
      brand
      expMonth
      expYear
      last4
    }
  }
`;

export const customersPayjpCardDestroy = `
  mutation($id: ID!) {
    customersPayjpCardDestroy(id: $id) {
        message
    }
}
`;

export const createPayjpCard = `
  mutation ($cardToken: String!) {
    customersPayjpCardCreate(cardToken: $cardToken) {
      message
    }
  }
`;

export const gmoPgCards = `
query {
  customersGmoPgCards {
    cardSeq
    expMonth
    expYear
    brand
    last4
  }
}
`;
export const softbankCards = `
query {
  customersSoftbankCards {
      brand
      expMonth
      expYear
      last4
  }
}
`;

export const modifySoftbankCards = `
mutation($cardToken: String!, $cardTokenKey: String!) {
  customersSoftbankCardModify(cardToken: $cardToken, cardTokenKey: $cardTokenKey) {
      message
  }
}
`;

export const deleteSoftbankCards = `
mutation {
  customersSoftbankCardDestroy {
      message
  }
}`;

export const customersGmoPgCardCreate = `
  mutation($cardToken: String!) {
    customersGmoPgCardCreate(cardToken: $cardToken) {
      message
    }
}
`;

export const customersGmoPgCardDelete = `
mutation($cardSeq: ID!) {
  customersGmoPgCardDestroy(cardSeq: $cardSeq) {
    message
  }
}
`;

export const customersPaygentCardCreate = `
mutation($cardToken: String!) {
  customersPaygentCardCreate(cardToken: $cardToken) {
    message
  }
}
`;
export const paygentCards = `
query  {
  customersPaygentCards {
    id
    brand
    expMonth
    expYear
    last4
  }
}
`;

export const customersPaygentCardDelete = `
  mutation($id: ID!) {
    customersPaygentCardDestroy(id: $id) {
      message
    }
  }
`;
