export const baseInfo = `
  query {
    baseInfo {
      logoUrl
      mobileLogoUrl
      addr01
      addr02
      businessHour
      companyName
      copyright
      fax
      goodTraded
      message
      pref
      shopName
      tel
      zip01
      zip02
      shippingCarrierBasedOn
      orderReceiptDisplayFlg
      subsOrderCancelableReason
      contactEmail
      enableShopProductList
    }
  }
`;
