export const signIn = `
  mutation ($email: String!, $password: String!, $rememberMe: Boolean) {
    customersSignIn(email: $email, password: $password, rememberMe: $rememberMe) {
      token
      message
    }
  }
`;

export const signUp = `
  mutation ($input: CustomersSignUpInput!) {
    customersSignUp(input: $input) {
        message
    }
  }
`;

export const self = `
  {
    customersSelf {
      email
      billingAddress {
        name01
        name02
        kana01
        kana02
        addr01
        addr02
        prefectureId
        tel01
        tel02
        tel03
        zip01
        zip02
      }
    }
  }
`;

export const customerInfo = `
  {
    customersSelf {
      email
      birthday
      sexId
      optin
      billingAddress {
        name01
        name02
        kana01
        kana02
        addr01
        addr02
        companyName
        prefectureId
        prefectureName
        tel01
        tel02
        tel03
        zip01
        zip02
      }
      shippingAddresses {
        id
        name01
        name02
        kana01
        kana02
        addr01
        addr02
        companyName
        prefectureId
        prefectureName
        tel01
        tel02
        tel03
        zip01
        zip02
        fullAddress
      }
    }
  }
`;

export const updateCustomer = `
  mutation ($input: CustomersUpdateInput!) {
    customersUpdate(input: $input) {
      customer {
        birthday
        billingAddress {
          name01
          name02
          kana01
          kana02
          addr01
          addr02
          prefectureId
          tel01
          tel02
          tel03
          zip01
          zip02
        }
        lockVersion
        optin
        sexId
      }
      message
    }
  }
`;

export const customersUpdatePassword = `
  mutation($input: CustomersPasswordUpdateInput!) {
    customersUpdatePassword(input: $input) {
        message
    }
  }
`;

export const customersPasswordResetEmailSubmit = `
mutation($email: String!) {
  customersPasswordResetEmailSubmit(email: $email) {
      message
      token
  }
}
`;

export const customersPasswordResetFormSubmit = `
mutation($input: CustomersPasswordResetInput!, $token: String!) {
  customersPasswordResetFormSubmit(input: $input, token: $token) {
      message
      token
  }
}
`;

