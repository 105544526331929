<template>
  <label
    class="card-block btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-start gap-5 h-100"
    :class="{ active }"
    @click="$emit('select', card)"
  >
    <section class="section-card-image">
      <img v-if="card.brand" :src="`/images/credit_cards/${brandImage}.png`" class="w-50px" />
      <img v-else :src="`/images/credit_cards/credit_card.png`" class="w-50px" />
    </section>

    <section class="d-flex align-items-start flex-column gap-3">
      <div class="section-card-brand">
        <div v-if="card.brand" class="d-flex align-items-center">
          <span>{{ card.brand }}</span>
        </div>
      </div>

      <div v-if="card.last4" class="text-dark fw-bolder">
        {{ card.last4.length > 4 ? card.last4 : "************" + card.last4 }}
      </div>
      <div v-if="card.expMonth && card.expYear" class="text-muted">
        exp {{ card.expMonth }}/{{ card.expYear }}
      </div>
    </section>

    <template v-if="deleteable">
      <span
        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
        @click="modalShow = true"
      >
        <IconClose></IconClose>
      </span>

      <b-modal
        v-model="modalShow"
        hide-header
        ok-title="OK"
        ok-variant="danger"
        cancel-title="取消"
        @ok="$emit('delete', card[cardIndexField])"
      >
        本当に削除しますか? <br />
        削除した情報は２度と復旧できません。
      </b-modal>
    </template>
  </label>
</template>

<script lang="ts" setup>
import { CustomersCardType } from "@/types/customers/CustomersPayjpCardType";
import { computed, ref } from "vue";
import { AVAILABLE_IMAGE_CARD_BRAND } from "@/helpers/constants";
import IconClose from "@/components/molecules/common/icons/svg/IconClose.vue";

interface Props {
  card: CustomersCardType;
  active?: boolean;
  deleteable?: boolean;
  cardIndexField?: string;
  brandFIeld?: string;
}

const props = defineProps<Props>();

defineEmits(["delete", "select"]);

const modalShow = ref(false);

const brandImage = computed(() => {
  if (!props.card.brand) return "";

  const brandName = props.card.brand.toLowerCase().replace(" ", "");

  if (brandName.includes("master")) return "icon_mastercard";
  else if (brandName == "amex") return "icon_americanexpress";
  else if (!AVAILABLE_IMAGE_CARD_BRAND.includes(brandName)) return "creadit_card";
  return `icon_${brandName}`;
});
</script>

<style scoped lang="scss">
.card-block {
  position: relative;

  .btn-icon {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-50%);
  }
}
</style>
