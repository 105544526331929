<template>
  <div v-if="visible" class="loading" :class="{ hidden: hidden }">
    <b-overlay
      :show="visible"
      no-wrap
      blur
      opacity="0.5"
      bg-color="transparent"
      z-index="5000"
      class="text-primary"
    >
      <b-card class="bg-transparent" :aria-hidden="visible ? 'true' : null"></b-card>
    </b-overlay>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useGlobalStore } from "@/stores/global";

const visible = ref(false);

const hidden = ref(true);

const globalStore = useGlobalStore();

const requests = computed(() => globalStore.requests);

watch(
  () => requests.value.length,
  (value) => {
    if (value > 0) {
      begin();
    } else {
      stop();
    }
  },
);

function begin() {
  hidden.value = false;
  visible.value = true;
}

function stop() {
  hidden.value = true;
  setTimeout(() => {
    visible.value = false;
  }, 300);
}

defineExpose({
  begin,
  stop,
});
</script>

<style lang="scss" scoped>
.loading {
  transition: 0.3s;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000000;

  &.hidden {
    opacity: 0;
  }
}
</style>
