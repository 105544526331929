<template>
  <div class="form-group">
    <input
      :id="id"
      :class="classes"
      :type="type"
      :maxlength="maxlength"
      :name="name"
      :value="modelValue"
      @input="updateValue($event.target.value)"
      @change="$emit('change', $event.target.value, $event)"
      :placeholder="placeholder"
      :disabled="disabled"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "vue";

export default defineComponent({
  name: "BInput",
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    maxlength: {
      type: [String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  setup(props, { attrs, emit }) {
    const inputRef = ref(null);

    const classes = computed(() => ["form-control", attrs.class]);

    function updateValue(value: any) {
      emit("update:modelValue", value);
      emit("input", value);
    }

    return {
      inputRef,
      classes,
      updateValue,
    };
  },
});
</script>

<style scoped>
/* custom styles */
input.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  height: 38px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
</style>
