import { CreditCardType } from "@/types/creditCard";
import { ref } from "vue";
import tokenPaygentError from "@/helpers/paygent_errors.js";

const linkCreditCard = (creditCard: CreditCardType, paygentInfo: any, toast: any, onSuccess) => {
  var paygentToken = new PaygentToken();

  paygentToken.createToken(
    paygentInfo.merchant_id,
    paygentInfo.token_generate_key,
    {
      card_number: creditCard.cardNumber.replace(/\s/g, ""),
      expire_year: creditCard.cardExpiry.split("/")[1],
      expire_month: creditCard.cardExpiry.split("/")[0],
      cvc: creditCard.cardCvc,
      name: creditCard.cardName,
    },
    execPurchase,
  );

  function execPurchase(result) {
    const code = result.result;
    if (code === "0000") onSuccess(result.tokenizedCardObject.token);
    else toast.error(tokenPaygentError[code]);
  }
};

export { linkCreditCard };
