import useGraphql from "@/composables/useGraphql";
import * as commonQueries from "@/graphql/queries/common";

import { defineStore, acceptHMRUpdate } from "pinia";
import _ from "lodash";

import { SharedGeneralsSelectOptionsType } from "@/types/shared/generals/SharedGeneralsSelectOptionsType";

export const useGlobalStore = defineStore({
  id: "global",

  state: () => ({
    keepError: false,
    validationErrors: {},
    validationBaseErrors: [],
    keepBaseError: false,
    requests: [] as string[],
    selectOptions: {} as any,
    resetData: false,
  }),

  actions: {
    getErrors(name: string) {
      return _.get(this.validationErrors, name, []);
    },

    setValidationErrors(payload: any, resetData: boolean) {
      if (!payload) return;

      const errors = {};
      Object.entries(payload).forEach(([key, value]) => {
        _.set(errors, key, value);
      });

      this.resetData = resetData;
      this.validationErrors = errors;
      this.validationBaseErrors = this.getErrors("base_errors");
    },

    resetValidations() {
      this.validationErrors = {};
    },

    resetValidationBaseErrors() {
      this.keepBaseError = false;
      this.validationBaseErrors = [];
    },

    addRequest(id: string) {
      this.requests.push(id);
    },

    removeRequest(id: string) {
      setTimeout(
        () => (this.requests = this.requests.filter((item) => item !== id)),
        300
      );
    },

    async fetchSelectOptions(keys: string[]) {
      const data = await useGraphql<SharedGeneralsSelectOptionsType>(
        commonQueries.selectOptions,
        {
          keys: keys,
        }
      );
      this.selectOptions = data;
    },
  },
  persist: {
    storage: window.localStorage,
    paths: [
      "validationErrors",
      "keepError",
      "validationBaseErrors",
      "keepBaseError",
    ],
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGlobalStore, import.meta.hot));
}
