import { v4 } from "uuid";
import { useFetch, UseFetchReturn } from "@vueuse/core";
import { useGlobalStore } from "@/stores/global";
import { useAuthStore } from "@/stores/auth";
import { useCartStore } from "@/stores/cart";
import { createToaster } from "@meforma/vue-toaster";

interface UseGraphqlOptions {
  toasted?: boolean;
  loading?: boolean;
}

export default function <T>(
  query: string,
  variables: unknown = {},
  options: UseGraphqlOptions = {}
): Promise<T> {
  const globalStore = useGlobalStore();
  const authStore = useAuthStore();
  const cartStore = useCartStore();
  const toast = createToaster({ position: "top-right" });

  return new Promise(async function (resolve, reject) {
    const id = v4();

    const { data, error, statusCode } = (await useFetch<T>("/graphql", {
      beforeFetch(ctx) {
        if (globalStore.keepError == false) {
          globalStore.validationErrors = {};
        }
        const token = authStore.token;
        if (!token) return ctx;

        ctx.options.headers = {
          ...ctx.options.headers,
          "United-Cart-Authorization": `Bearer ${token}`,
        };

        if (options.loading !== false) {
          globalStore.addRequest(id);
        }

        return ctx;
      },

      afterFetch(ctx) {
        const errors = ctx.data.errors;

        if (errors && errors.length) {
          const firstError = errors[0];
          const codeErrors = [301, 422];

          if (codeErrors.includes(firstError.code)) {
            globalStore.setValidationErrors(
              firstError.errors,
              firstError.reset_data
            );
          }

          if (
            firstError.code == 401 &&
            window.location.pathname != "/shop/customers/sign_in"
          ) {
            window.location.href = "/shop/customers/sign_in";
          }

          toast.error(firstError.message);
          reject(new Error(firstError.message));
        }

        const firstKey = Object.keys(ctx.data.data)[0];
        ctx.data = ctx.data.data[firstKey];

        return ctx;
      },
    })
      .post({ query, variables })
      .json()) as UseFetchReturn<T>;

    if (options.loading !== false) {
      globalStore.removeRequest(id);
    }

    if (error.value) {
      if (statusCode.value === 401) {
        authStore.logout();
        cartStore.$reset();

        if (window.location.href.includes("/shop/")) {
          window.location.href = "/shop/customers/sign_in";
        }
      }

      toast.error(error.value);
      reject(new Error(error.value));
    }

    if (data.value) {
      resolve(data.value);

      const message = (data.value as any).message;

      if (message && options.toasted) {
        toast.success(message);
      }
    }
  });
}
