import useGraphql from "@/composables/useGraphql";
import { defineStore, acceptHMRUpdate } from "pinia";
import * as baseInfoQueries from "@/graphql/queries/base_info";
import { BaseInfoType } from "@/types/BaseInfoType";

interface State {
  baseInfo: BaseInfoType | null;
}

export const useBaseInfoStore = defineStore({
  id: "baseInfo",

  state: (): State => ({
    baseInfo: null,
  }),

  actions: {
    async fetchBaseInfo() {
      const data = await useGraphql<BaseInfoType>(baseInfoQueries.baseInfo);
      this.baseInfo = data;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBaseInfoStore, import.meta.hot));
}
