const tokenPaygentError = {
  1100: "マーチャントID - 必須エラー",
  1200: "トークン生成公開鍵 - 必須エラー",
  1201: "トークン生成公開鍵 - 不正エラー",
  1300: "カード番号 - 必須チェックエラー",
  1301: "カード番号 - 書式チェックエラー",
  1400: "有効期限(年) - 必須チェックエラー",
  1401: "有効期限(年) - 書式チェックエラー",
  1500: "有効期限(月) - 必須チェックエラー",
  1501: "有効期限(月) - 書式チェックエラー",
  1502: "有効期限(年月)が不正です。",
  1600: "セキュリティコード - 書式チェックエラー",
  1601: "セキュリティコード - 必須エラー（セキュリティコードトークンの場合）",
  1700: "カード名義 - 書式チェックエラー",
  7000: "非対応のブラウザです。",
  7001: "ペイジェントとの通信に失敗しました。",
  8000: "システムメンテナンス中です。",
  9000: "ペイジェント決済システム内部エラー",
};

export default tokenPaygentError;
