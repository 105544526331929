import moment from "moment";

export default function validateBirthday(birthday: string | null, maxYears = 100) {
  const today = moment();

  const max = today.clone().subtract(maxYears, "years");

  const birthdayMoment = moment(birthday);

  if (birthdayMoment.isSameOrAfter(max) && birthdayMoment.isSameOrBefore(today)) {
    return true;
  } else {
    return false;
  }
}
